import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import Video from '../../../../static/video_button.mp4';

const NikkeGuidesNaga: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Naga guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_naga.jpg"
            alt="Naga"
          />
        </div>
        <div className="page-details">
          <h1>Naga guide & review</h1>
          <h2>A guide & review for Naga in NIKKE: Goddess of Victory.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <Alert variant="primary alert-red">
          <p>The review has been updated on 8.10.2023.</p>
        </Alert>
        <SectionHeader title="Banner" />
        <StaticImage
          src="../../../images/nikke/generic/banner_naga.jpg"
          alt="Naga"
        />
        <p>
          <strong>Naga</strong> banner will be available from{' '}
          <strong>10/5 12:00 ~ 10/19 04:59 (UTC+9).</strong>
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="naga" enablePopover />
        </div>
        <p>
          We all love gyarus, don't we! Naga is the new stylish Nikke in town as
          we get a closer look into the lives of Marciana's students. Naga and
          her Big Sis attitude towards Tia comes paired with tons of unique
          skills which we will take a look into soon. As taught by Marciana,
          Naga heals HP of all her allies. However, it seems that she's a
          scholar student, proving to be even more competent than her teacher.
          She also happens to heal cover, and gives a new unique buff where she
          increases damage dealt when attacking core! She also has a small
          cheeky ATK buff, which can turn into a very substantial ATK buff if
          paired with specific Nikkes (especially Tia!). Be careful around Naga
          though, she can pierce right through your hearts with the extra pierce
          buff that her burst provides for herself!
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          Naga's kit is pretty straightforward, but it does incorporate some new
          terms that we have not seen before on other Nikkes. One of them is the
          new and self explanatory Damage Dealt while Attacking Core buff that
          she provides with her Skill 1 (Guardian of Friendship) and Skill 2
          (Support of Friendship). These buffs, when active, will increase your
          attack damage whenever you shoot the core of a rapture. All Nikkes
          already have a 200% damage buff when attacking core (except for some
          being 250%), which means this buff is already very saturated.
        </p>
        <Alert variant="primary" className="alert-update">
          <p>
            Note that core damage buffs are further diluted by other damage
            modifiers in the same bracket: critical hits + critical damage (base
            50%), range bonus damage (30%), and full burst bonus (50%). For
            instance, if Alice hits a core during full burst at optimal distance
            with a critical hit, then the total multiplier is 3.3x
            (1+1+0.5+0.5+0.3).
          </p>
        </Alert>
        <p>
          However, do not fret. There is a reason Naga and Tia have been getting
          shouts of being meta. Despite being diluted, the value of this Damage
          Dealt while Attacking Core is satisfactory. When both S1 and S2 core
          damage buffs are active, Naga provides a total of 120.24% core damage
          buff. Not only that, the uptime of these buffs is also long (but
          currently requires Tia to be paired with Naga to have high uptime for
          S1). Her Skill 2 reactivates every 4.3 seconds and lasts for 5
          seconds, so theoretically it is permanent!
        </p>
        <p>
          Her Skill 1's buffs, however, along with her Burst's extra ATK Buffs,
          are locked behind a unique requirement: Activates when applying
          Shield. This trigger is fulfilled only if Naga's skills are activated
          when she possesses an Independent Shield, shown as a cyan bar on top
          of her HP. Dependent or Shared Shields such as the ones belonging to
          Centi, Poli, or Blanc cannot trigger this skill as the shield is
          placed in front of them instead. This means that her Skill 1's
          team-wide core damage buff will only activate with the help of a
          team-shielder like Aria, Folkwang, Ether, or her close acquaintance
          Tia. She also needs to have the Shield present when she bursts to
          upgrade her small 16.18% ATK into the potentially game-changing 47.20%
          ATK buff.
        </p>
        <Row xs={1} xl={2} xxl={2} className="comparison">
          <Col>
            <div className="box">
              <StaticImage
                src="../../../images/nikke/generic/naga_1.jpg"
                alt="Naga"
              />
              <p>
                Centi providing Shield to only herself, hence S1 not activated.
              </p>
            </div>
          </Col>
          <Col>
            <div className="box">
              <StaticImage
                src="../../../images/nikke/generic/naga_2.jpg"
                alt="Naga"
              />
              <p>
                Aria providing shield to Naga, hence S1 core damage buff is
                activated
              </p>
            </div>
          </Col>
        </Row>
        <p>
          One thing to note is that her Burst (As Long As We're With Friends)
          provides Caster's ATK Buff, which is essentially worse than, say,
          Liter's ATK Buff if we are talking about raw buffs for Attackers. This
          is due to Supporter Type Units having weaker Base ATK stats than
          Attackers.
        </p>
        <Alert variant="primary" className="alert-update">
          <p>
            However, these kinds of buffs are actually more beneficial for other
            Supporters and Defenders. Most of the time, they will be less
            invested than Naga. Defenders like Tia also rely on lifesteal to
            heal herself. Since her base ATK stats are poor, she will benefit
            more from non-self ATK buffs like the ones from Naga!
          </p>
        </Alert>
        <p>The frequency of activation for her Skills is as follows:</p>
        <h6>For Naga S1</h6>
        <ul>
          <li>Her Cover HP recovery will proc averagely every 10.3s.</li>
          <li>
            Whenever she is shielded, she provides extra core damage buff, the
            uptime of which is dependent on the shielder paired with her.
          </li>
          <li>
            Ideally, use a 10 seconds cooldown-on-shield Nikke like Tia to make
            the best use of Naga's Core Damage Buffs.
          </li>
        </ul>
        <h6>For Naga S2</h6>
        <ul>
          <li>
            Her S2 will proc approximately every 4.3s, theoretically giving her
            S2's core damage buff a 100% uptime.
          </li>
          <li>
            Due to her fast S2 procs, she's one of the best single target
            non-burst Healer. Better than Rapunzel and Pepper.
          </li>
        </ul>
        <h6>For Naga Burst</h6>
        <ul>
          <li>10 second Pierce every Burst.</li>
          <li>
            10 second ATK Buff, value dependent on Shielder paired with her.
          </li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Performance Analysis" />
        <p>
          In Campaign, Naga works extremely well as all of her kit plays a part
          in helping the team do better. Her core damage buffs are highly
          appreciated since almost all raptures have a core which can be
          targeted to make use of her S1 and S2. Her HP and Cover heals are also
          not to be forgotten as she allows the team to heal right up, along
          with the ATK buff she provides when she bursts. Naga is also extremely
          flexible. She can work as an off burst healer and buffer, while also
          being able to work as the Main B2 if required in specific team comps.
          The highlight of her abilities can be seen with Tia, who allows Naga
          to unlock her full potential. However, Tia may need micromanagement
          due to her taunt all enemies for every 5 shots she makes, making her
          harder to use than other team comps. If you can get over that, the
          buffs being provided by these 2 are more than enough to help you clear
          stages, even when heavily under the CP requirement.
        </p>
        <p>
          In Missilis Tribe Tower, Naga can act as a Team-wide Healer for the
          team, which previously was a job dedicated for Pepper. She beats out
          Pepper in heals while constantly allowing Liter to Burst, making her
          even more useful since majority of Pepper's heals come from her Burst.
          Not just that, she can be the Main B2 for stages that do not require
          Centi's Burst Gen. Of course, her duo pair Tia will allow Naga to
          shine even more in Tribe Tower when she accompanies her soon.
        </p>
        <p>
          In Arena, Naga won't be seeing much spotlight. Her kit being locked
          behind annoying triggers like Independent Shield will make team
          formation a bit more clunky. The worst part? You need a B1 shielder to
          activate the major part of her Burst Skill, and the only unit that can
          do that, Ether, is also underwhelming at most. She is average, or
          rather below average, as most of her skills are useless for PvP (what
          core?) except for the minor ATK buffs that get overshadowed by other
          B2 like Poli or Blanc. Wielding a non-clip Shotgun, she also feeds
          Jackal while providing not-so-good burst generation.
        </p>
        <h5>Why Tia?</h5>
        <p>
          We've lately been talking about Shielders, and have specifically
          pointed out Tia to be the best pair for her. But why is that? Let's
          talk about her performance with and without Tia.
        </p>
        <h5>Naga with Tia</h5>
        <video controls>
          <source src={Video} type="video/mp4" />
        </video>
        <p>
          Tldr: Heals, Cover heals, Huge Core damage buffs and Substantial ATK
          buffs. Plus, Tia's button cannon can cause destruction.
        </p>
        <p>
          At a glance, it simply feels meta. A very very decent core damage
          buff, since Tia unlocks the second part of Naga's S1 and allows her to
          buff all allies every burst rotation, which stacks with her S2 core
          damage buff. This is not as good a buff as an ATK buff per say, but
          since the value being provided is so high, it is overall a decent
          damage increase. Tia also allows Naga to unlock her extra ATK buff
          during burst, every burst rotation, which helps Naga give an
          additional 48% instead of 16% ATK to the whole team. In some
          situations, these two will be a better support than even the current
          best supports: Blanc and Noir!
        </p>
        <p>
          In return, Naga activates her Big Sis instincts and decides to help
          Tia survive, who is possibly constantly taunting the enemies. Here is
          a literal insight on what they both provide together.
        </p>
        <Alert variant="primary" className="alert-update">
          <h5>Tia & Naga together collective buffs</h5>
          <ul>
            <li>Core damage buff 125.24% every burst</li>
            <li>Attack damage buff 32.11%</li>
            <li>ATK buff 47.20% every burst</li>
          </ul>
          <h5>
            The remaining skills act to allow Tia to survive, in order to
            counter the damage she will take during her possibly constant taunt:
          </h5>
          <ul>
            <li>
              Cover Max HP buff every 5 shots, need to make sure to wait 5
              seconds before you make 5 shots for perfect use with Tia.
            </li>
            <li>
              Cover heals at burst, recovers even more cover HP for damage
              dealt.
            </li>
            <li>Shield to prevent cover from taking damage at burst.</li>
            <li>Cover heals every 10.1 seconds.</li>
            <li>HP heals every 4.3 seconds.</li>
          </ul>
        </Alert>
        <h5>Point to Note</h5>
        <p>
          The one and only Disadvantage to this pair is that Tia has a constant
          taunt, and hence may need manual use and micromanagement to allow her
          to survive a possible onslaught of damage dished out by raptures. This
          may also discourage use of other Manual heavy Nikkes, especially
          Alice. For this, we need to wait for testing with Tia to see how
          viable a constant taunt is for her, and whether this Tia Naga duo can
          survive the onslaught of damage that the raptures throw at us
        </p>
        <h5>Naga without Tia</h5>
        <p>
          Tldr: Heals HP, Cover heals, small buffs to core damage, and a very
          minor ATK buff. Trouble with synergy with other shielders causing her
          S1 to be extremely restricted. To put it simply. she doesn't unlock
          her full potential without Tia.
        </p>
        <p>
          Without Tia, Naga will still heal a decent amount of cover HP and
          significantly recover HP of allies. Her S2 will still provide constant
          uptime for her Damage dealt when attacking core, no matter how diluted
          it may be. Her Burst will still allow her to Pierce and provide a
          small ATK buff. However, she will miss out on a large chunk of the
          actual buffs she is providing. Some shielders do allow to periodically
          or situationally activate some parts of her skills, but for the most
          part, she will be not be providing a large chunk of her buffs, as
          shown in the table below:
        </p>
        <Table bordered responsive className="cones-list">
          <thead>
            <tr>
              <th>Buff Name</th>
              <th>Naga with Tia</th>
              <th>Naga without Tia</th>
              <th>% Buff not being provided consistently without Tia</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="stat">Damage dealt when attacking core</td>
              <td>125.24%</td>
              <td>40.07%</td>
              <td>85.17%</td>
            </tr>
            <tr>
              <td className="stat">Caster's ATK Buff</td>
              <td>47.20%</td>
              <td>16.18%</td>
              <td>31.02%</td>
            </tr>
          </tbody>
        </Table>
        <p>
          Since we now know enough about Tia with Naga, let's talk about how
          other shielders work with Naga.
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="aria" enablePopover />
        </div>
        <p>
          During Aria's burst, it'll help her activate higher core damage buffs
          from S1. This does help somewhat since Aria will have 100% hit-rate
          and since she is an Attacker MG, she will output decent damage.
          However this pair still isn't unlocking her full kit, she won't give
          the extra ATK buff during her own burst (which she will unlock with
          Tia, Ether or Folkwang), and the uptime on her S1 core damage buff is
          severely reduced, since she'll only give it every 40 seconds.
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="folkwang" enablePopover />
        </div>
        <p>
          Having Naga as the second Highest ATK is a blow to team-building,
          hence we do not recommend this pair at all. However, Folkwang can
          allow Naga to activate S1 during Folkwang's burst, and if Naga Bursts
          when Folkwang's Skill 1 shield is active, she will provide the
          teamwide 47.20% ATK buff.
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="ether" enablePopover />
        </div>
        <p>
          Ether is a 40s B1, whose burst will provide shield for 3 lowest HP
          Nikkes, so hopefully your Naga ticks this box when Ether is about to
          Burst. If she does, every 40 seconds, your Naga will activate her S1's
          core damage buff, and will provide the 47.20% ATK buff for all allies.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          You may Overload her gear to boost your CP in campaign, or in general
          to increase Base ATK Stat so that Naga provides more ATK Buff from her
          Burst. However, remember that ATK rolls on her Overload gear NOT
          increase the Caster's ATK buff she provides on Burst. One ideal roll
          for Naga is MAX AMMO, which reduces her reload frequency and allows
          her to activate certain skills more frequently. ATK and ELEMENTAL
          DAMAGE are also great as secondary options to boost her DPS slightly.
        </p>
        <h5>Skill Investments</h5>
        <ul>
          <li>
            Naga's Skill 1 is top priority due to the value of Core Damage buff
            she provides in it, along with the Cover Heal to help Tia.
          </li>
          <li>
            Her Burst is a close second in priority due to the Caster's Attack
            Buff that she provides.
          </li>
          <li>
            Her Skill 2 comes last. The value gain per level is comparably low
            versus the other two, but it has a nearly permanent uptime. The
            increased heals may also be useful.
          </li>
        </ul>
        <Alert variant="primary" className="alert-update">
          <p>
            We recommend starting with 5/5/5, then 7/5/7, then 10/7/10. To
            conserve materials, you may choose to upgrade S2 beyond 5 only if
            the extra healing is needed.
          </p>
        </Alert>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_3_sm.png"
          alt="Marciana"
        />
        <StaticImage
          src="../../../images/nikke/generic/cube_4_sm.png"
          alt="Marciana"
        />
        <p>
          Resilience Cube or Bastion Cube will be the best options to use with
          Naga, as both promote Naga land more shots. Wingman Cube is also a
          decent alternative.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Team Compositions" />
        <h5>For campaign</h5>
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="placeholder-box">?</div>
          </div>
        </div>
        <p>
          This is the usual way to setup a Tia Naga team. Tia and Naga pair up
          with a 20s cooldown B1 CDR unit, and 2 B3s of your choice can be used.
          The direct problem with this setup only exists due to the fact that
          Tia has a taunt which she needs to survive with. This is generally way
          more viable to have against bosses, but against multiple enemies it
          may pose problems. As we await testing with Tia, we can only make
          guesses on how she will need micromanaging and manual use to ensure
          her survival in specific cases. Tia basically will work like Noise,
          however instead of HP, we will need to save her Cover.
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 3</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="placeholder-box">?</div>
          </div>
        </div>
        <p>
          This team showcases a way to use Naga as a solo B2. She will provide
          heals for HP and Cover for the team, along with minor core damage
          buffs and ATK buffs.
        </p>
        <h5>Team #3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 3</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Slot 5</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Tia, Ether, Aria and Folkwang are shielder units that can be paired
          with Naga to try and make use of her kit. A comparison for all
          shielders was given in the "Why Tia?" section.
        </p>
        <h5>For Tribe Tower</h5>
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="laplace" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
              <NikkeCharacter mode="icon" slug="drake" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Naga is used as a healer and buffer to allow the team to survive.
          Drake can provide extra DPS, or Centi can provide Burst Gen for the
          team.
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="laplace" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
        </div>
        <p>Basic Tia Naga comp, similar to her Campaign setup.</p>
        <h5>For Raids</h5>
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 3</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 5</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
        </div>
        <p>
          For this team, you can try out a few things. You can focus on making
          the following element based teams:
        </p>
        <ul>
          <li>Snow White, Yulha/Maxwell - for Iron Weak raptures</li>
          <li>2B, A2 - for Fire Weak raptures</li>
          <li>Scarlet/Guillotine SAnis - for Electric Weak raptures</li>
        </ul>
        <p>
          There are no substantial DPS for Water and Wind as it stands, so a
          general alternative could be one of the elemental teams already
          listed, or:
        </p>
        <ul>
          <li>A2 + B3 when there are tons of Boss Parts.</li>
          <li>2B + Scarlet - as a general DPS team.</li>
        </ul>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 5</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
        </div>
        <p>
          If there is no need to micromanage Tia, you can definitely try out
          using her with manual-heavy Nikkes like Alice.
        </p>
        <h5>Team #3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 5</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
        </div>
        <p>
          General DPS team for SAnis, where Naga is being buffed due to her
          element by SAnis, Biscuit Burst buffing Naga, Dorothy providing CDR
          and extra DPS, and finally Privaty allowing Dorothy to proc her S1
          more, and allowing SAnis to do more damage. Naga introduces extra
          heals, and minor ATK and core damage buffs to this team, along with
          her cover heals.
        </p>
        <h5>Team #4</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team and the previous team in general exists to just increase
          SAnis' damage output, however SAnis does waste a lot of Naga's
          potential. Anis' major damage output is from her Skill 2, which does
          not attack enemy core. Think of this is as a general DPS team.
        </p>
        <h5>Team #5</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Slot 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Slot 3</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 4</h5>
            <div className="placeholder-box">?</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Slot 5</h5>
            <div className="placeholder-box">?</div>
          </div>
        </div>
        <p>
          Non-Tia teams, where Naga can act similarly to Marciana as a healer
          for the team, while also providing adequate buffs.
        </p>
        <SectionHeader title="Should You Pull" />
        <Alert variant="primary alert-red">
          <p>
            There is a chance that the anniversary unit coming soon will break
            the game (in a positive way), in which case you may choose to skip
            Tia and Naga. This is only if you are currently short of gems,
            especially for beginners. We still recommend getting one copy,
            however. Alternatively, you can try to pull Tia and Naga and rely on
            Golden Tickets to acquire the anniversary unit.
          </p>
        </Alert>
        <p>
          We currently do not have a wide variety of B2 healers available to us.
          Right after Marciana, we have received a possible gem of a Nikke. Not
          only will Naga provide you with tons of heals, but she also has unique
          quirks and buffs, such as healing your cover, and providing you with
          Core damage and ATK buffs. You should definitely pull Naga. She seems
          to be an answer to a lot of current problems, especially in Missilis
          Tribe Tower, and provides additional options to use in Union and Solo
          Raids. Just remember that she does need to be paired with Tia to prove
          herself as the ultimate support. We believe that The Tiga (Tia+Naga)
          pair will probably shake and shift the meta around in the upcoming
          Solo Raid.
        </p>
        <p>
          If you are an F2P, we recommend you to get one copy, and then wishlist
          her dupes if you want any. Since you will be able to wishlist her
          after Naga's Banner ends, we also urge you to not blindly spark her
          with golden tickets, unless if you already got Tia withing 10-30
          pulls, and have already spent 80-90 tickets trying to get her. Any
          case apart from this one, we do not recommend sparking Naga with your
          golden mileage tickets.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>One of the best Off-Burst Healers.</li>
                  <li>
                    Provides a unique Buff: Damage dealt while attacking core.
                  </li>
                  <li>Provides HP and Cover Heals.</li>
                  <li>
                    Assists her major pair, Tia, heavily in her survivability.
                  </li>
                  <li>
                    May provide higher offensive support than Bunnies when all
                    buffs are effective,
                  </li>
                  <li>
                    Buffs are retained across mob waves (unlike Blanc's debuff),
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Dependent on shielder Nikkes like Tia, Ether, Folkwang or
                    Aria to unlock parts of her Skills.
                  </li>
                  <li>
                    Core Damage Buff can be heavily diluted depending on team
                    comps or stage.
                  </li>
                  <li>
                    Burst without being shielded barely provides anything.
                  </li>
                  <li>
                    Less versatile than Bunnies and only optimally works with
                    units that can hit cores all the time.
                  </li>
                  <li>
                    You would always want to run her with Tia, else her
                    performance is lowered.
                  </li>
                  <li>With Tia, you're forced to run a 2-1-2 formation.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <Alert variant="primary alert-red">
          <p>
            These ratings assume that Naga is paired with Tia for maximum
            performance.
          </p>
        </Alert>
        <div className="ratings-container">
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (Early)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (Late)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesNaga;

export const Head: React.FC = () => (
  <Seo
    title="Naga guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Naga in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
